import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
} from 'ra-ui-materialui';
import { required } from 'react-admin';
import { Typography } from '@mui/material';

const validateNonNegative = (value: any) => (value >= 0 ? undefined : 'Value must be a non-negative number');

export const NodeSales = () => {
  return (
    <div style={{ marginTop: '1rem' }}>
      <Typography variant="h4">Node Sales</Typography>
      <ArrayInput source="nodeSales" label="">
        <SimpleFormIterator>
          <DateInput source="start_date" label="Start Date" />
          <DateInput source="end_date" label="End Date" />
          <ReferenceInput source={'launchpad_id'} reference="ido-platforms" resource="ido-platforms" perPage={1000}>
            <AutocompleteInput label="Nodesale Platform" optionText="name" />
          </ReferenceInput>
          <BooleanInput source="self_distributed" label="Self Distributed" />
          <ArrayInput source="tiers" label="Node Sale Tiers">
            <SimpleFormIterator>
              <TextInput source="name" label="Tier Name" validate={required()} />
              <TextInput
                source="node_price"
                label="Node Price"
                type="number"
                validate={[required(), validateNonNegative]}
              />
              <TextInput
                source="total_nodes"
                label="Total Nodes"
                type="number"
                validate={[required(), validateNonNegative]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};
